import Link from "next/link";
import { IconBaseProps } from "react-icons/lib";
import Image from "next/image";
import nav from "./AdminNav.module.scss";
import { NavLink } from "./NavLink";
import { UserSection } from "./UserSection";
import { LogoSmallIcon } from "@cakemembers/components-core";

export function AdminNav({
  subheading,
  children,
  switcher,
}: {
  subheading: string;
  children?: React.ReactNode;
  switcher?: React.ReactNode;
}) {
  return (
    <nav className="flex flex-col min-h-screen bg-[#f3ece6] md:min-w-[275px]">
      <div className="md:h-[100px] border-b border-b-black md:border-b-0">
        <Link
          href="/admin"
          className="py-3 px-1 md:p-4 flex flex-col gap-2 text-white"
        >
          <Image
            alt="Cake Logo"
            src="/images/logo.svg"
            className="w-[132px] h-[38px] hidden md:flex"
            height={100}
            width={100}
          />
          <span className="text-sm text-secondary uppercase hidden md:flex">
            {subheading}
          </span>
          <div className="flex justify-center items-center w-8 h-8 bg-dark rounded-full p-2 md:hidden">
            <LogoSmallIcon className="fill-white" fill="white" />
          </div>
        </Link>
      </div>
      {switcher}
      <div className="grow flex flex-col">
        <nav className={nav.Nav}>{children}</nav>
      </div>
      <UserSection />
    </nav>
  );
}

export function NavItem({
  title,
  href,
  icon: Icon,
  match,
  children,
}: {
  title: string;
  href: string;
  icon: React.FC<IconBaseProps>;
  match: string[];
  children?: React.ReactNode;
}) {
  return (
    <>
      <NavLink
        href={href}
        match={match}
        linkInner={
          <>
            <Icon /> <span className="hidden md:flex">{title}</span>
          </>
        }
      >
        {children ? <div>{children}</div> : undefined}
      </NavLink>
    </>
  );
}
