import {
  Badge,
  ClockIcon,
  FavoriteFilledIcon,
  TagIcon,
  Text,
} from "@cakemembers/components-core";
import {
  Brand,
  BrandsSortOptions,
  Member,
  MemberCollectionItem,
} from "@cakemembers/types";
import classNames from "classnames";
import Link from "next/link";
import { SanityImageServer } from "../images";
import { LogoSpace } from "./LogoSpace";

export function BrandGridShell({
  children,
  singleColLayout,
}: {
  children: React.ReactNode;
  singleColLayout?: boolean;
}) {
  return (
    <div className="@container">
      <div
        className={classNames(
          singleColLayout
            ? "grid-cols-1"
            : "grid-cols-2 @5xl:grid-cols-4 @6xl:grid-cols-6",
          "grid gap-2 @3xl:gap-4"
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function BrandGrid({
  brands,
  member,
  linkToBrand = true,
  singleColLayout = false,
  showMinSpend = false,
  sort,
  top,
  isFavorite,
}: {
  brands: Brand[];
  member?: Member;
  linkToBrand?: boolean;
  singleColLayout?: boolean;
  showMinSpend?: boolean;
  sort?: BrandsSortOptions;
  top?(brand: Brand): React.ReactNode;
  isFavorite?(brandSlug: string): boolean;
}) {
  return (
    <BrandGridShell singleColLayout={singleColLayout}>
      {brands.map((b, idx) => (
        <GridItem
          key={b.db.id}
          brand={b}
          pass={member?.collection.itemMap[b.db.slug]}
          linkToBrand={linkToBrand && b.db.status !== "coming_soon"}
          top={top && top(b)}
          showMinSpend={showMinSpend}
          singleColLayout={singleColLayout}
          sort={sort}
          idx={idx}
          isFavorite={isFavorite && isFavorite(b.db.slug)}
        />
      ))}
    </BrandGridShell>
  );
}

function GridItem({
  brand,
  idx,
  pass,
  linkToBrand,
  top,
  singleColLayout = false,
  showMinSpend,
  sort,
  isFavorite,
}: {
  brand: Brand;
  idx: number;
  pass?: MemberCollectionItem;
  linkToBrand?: boolean;
  top?: React.ReactNode;
  singleColLayout?: boolean;
  showMinSpend?: boolean;
  sort?: BrandsSortOptions;
  isFavorite?: boolean;
}) {
  let linkClass: string | undefined = undefined;
  let cardClass = "w-full aspect-[215/260] relative group";
  const showWideImage =
    (idx % 3 === 0 && (!sort || sort === "featured")) || singleColLayout;

  if (showWideImage) {
    linkClass = "col-span-2";
    cardClass = "w-full aspect-[447/260] relative group";
  }

  return (
    <Link
      className={classNames(
        linkClass,
        !linkToBrand && "cursor-default",
        "group hover:md:drop-shadow-xl"
      )}
      href={
        linkToBrand ? `?brand=${brand.db.slug}&sort=${sort || "default"}` : "#"
      }
    >
      <div className={cardClass}>
        {isFavorite && (
          <div className="absolute top-1 right-1 z-[1]">
            <div className="rounded-full bg-black/20 w-6 h-6 flex justify-center items-center">
              <FavoriteFilledIcon className="w-3 h-3 fill-white" />
            </div>
          </div>
        )}
        <figure className="absolute top-0 w-full h-full ">
          {showWideImage
            ? brand.cms?.imageWide && (
                <SanityImageServer
                  alt={`${brand.cms?.name} Logo`}
                  image={brand.cms?.imageWide}
                  height={1080}
                  width={1080}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  aspectRatio={260 / 447}
                  className={classNames(
                    "group-hover:md:scale-[102%]  transition-transform duration-300 ease-in-out",
                    brand.db.status === "coming_soon" && "grayscale",
                    brand.db.status === "out_of_stock" && "grayscale"
                  )}
                />
              )
            : brand.cms?.imageTall && (
                <SanityImageServer
                  alt={`${brand.cms?.name} Logo`}
                  image={brand.cms?.imageTall}
                  height={1080}
                  width={1080}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  aspectRatio={260 / 215}
                  className={classNames(
                    "group-hover:md:scale-[102%]  transition-transform duration-300 ease-in-out",
                    brand.db.status === "coming_soon" && "grayscale",
                    brand.db.status === "out_of_stock" && "grayscale"
                  )}
                />
              )}
        </figure>
        <div className="w-full h-full absolute top-0 margin-top-auto bg-black/25 group-hover:md:bg-transparent group-hover:md:scale-[102%] transition-transform duration-300 ease-in-out"></div>
        <div className="absolute top-0 w-full h-full p-4">
          <LogoSpace>
            {brand.cms?.imageLogo ? (
              <SanityImageServer
                alt={`${brand.cms?.name} Logo`}
                image={brand.cms?.imageLogo}
                height={500}
                width={500}
                style={{
                  maxHeight: "33%",
                  maxWidth: "66%",
                  objectFit: "contain",
                }}
                className="invert"
              />
            ) : (
              <h1 className="text-white text-5xl">
                {brand.cms?.name || brand.cms?.slug}
              </h1>
            )}
          </LogoSpace>
        </div>
        <div className="absolute bottom-0 w-full p-2 flex flex-wrap gap-2 items-end justify-end">
          {pass && (
            <Badge className="flex gap-1 items-center" size="sm">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M8.5 2C7.31331 2 6.15328 2.35189 5.16658 3.01118C4.17989 3.67047 3.41085 4.60754 2.95673 5.7039C2.5026 6.80025 2.38378 8.00665 2.61529 9.17054C2.8468 10.3344 3.41825 11.4035 4.25736 12.2426C5.09648 13.0818 6.16557 13.6532 7.32946 13.8847C8.49335 14.1162 9.69975 13.9974 10.7961 13.5433C11.8925 13.0891 12.8295 12.3201 13.4888 11.3334C14.1481 10.3467 14.5 9.18669 14.5 8C14.4983 6.40924 13.8656 4.88413 12.7407 3.75929C11.6159 2.63445 10.0908 2.00175 8.5 2ZM10.7242 7.2242L8.3242 9.6242C8.21169 9.73668 8.0591 9.79987 7.9 9.79987C7.7409 9.79987 7.58832 9.73668 7.4758 9.6242L6.2758 8.4242C6.16651 8.31104 6.10603 8.15948 6.1074 8.00216C6.10876 7.84484 6.17187 7.69435 6.28311 7.58311C6.39436 7.47186 6.54484 7.40876 6.70216 7.40739C6.85948 7.40603 7.01104 7.4665 7.1242 7.5758L7.9 8.3516L9.8758 6.3758C9.98896 6.2665 10.1405 6.20603 10.2978 6.20739C10.4552 6.20876 10.6056 6.27186 10.7169 6.38311C10.8281 6.49435 10.8912 6.64484 10.8926 6.80216C10.894 6.95948 10.8335 7.11104 10.7242 7.2242Z"
                  fill="white"
                />
              </svg>
              <span>In My Wallet</span>
            </Badge>
          )}
          {showMinSpend &&
            brand.cms?.minSpend &&
            brand.db.status !== "coming_soon" &&
            brand.db.status !== "out_of_stock" && (
              <Text className="text-white px-3" face="sans">
                ${brand.cms.minSpend} min. spend
              </Text>
            )}
          {brand.db.status === "coming_soon" && (
            <Badge className="flex gap-1 items-center" size="sm">
              <ClockIcon /> Coming Soon
            </Badge>
          )}
          {brand.db.status === "out_of_stock" && (
            <Badge className="flex gap-1 items-center" size="sm">
              <TagIcon /> Out of Stock
            </Badge>
          )}
        </div>
        {top && <Top>{top}</Top>}
      </div>
    </Link>
  );
}

export function Top({ children }: { children: React.ReactNode }) {
  return (
    <div className="absolute top-0 w-full h-full p-2 flex flex-col justify-start items-end">
      {children}
    </div>
  );
}
